
import {modal} from './function/modal'
/**
 * Modern Browser
 */
document.documentElement.className = "js";

var supportsCssVars = function () {
    var e, t = document.createElement("style");
    return t.innerHTML = "root: { --tmp-var: bold; }", document.head.appendChild(t), e = !!(window.CSS && window.CSS.supports && window.CSS.supports("font-weight", "var(--tmp-var)")), t.parentNode?.removeChild(t), e
};
supportsCssVars() || alert("Please view this site in a modern browser that supports CSS Variables.");

/**
 * Navbar
 */
const hamburger = document.querySelector(".hamburger");
const navMenu = document.querySelector(".nav-menu");

hamburger?.addEventListener("click", mobileMenu);

function mobileMenu() {
    hamburger?.classList.toggle("active");
    navMenu?.classList.toggle("active");
}

const navLink = document.querySelectorAll(".nav-link");

navLink.forEach(n => n.addEventListener("click", closeMenu));

function closeMenu() {
    hamburger?.classList.remove("active");
    navMenu?.classList.remove("active");
}



const url = window.location.href;
const segments = url.split('/'); 
const lang = segments[3];
const today = new Date(); 
const cutoffDate = new Date("2025-04-01"); 

if (today < cutoffDate) { 
   if (lang === "it") {
      modal( "Informazioni", "Riapertura 18 Aprile / Re-opening April 18th","");
   } else if (lang === "en") {
      modal( "Information", "Riapertura 18 Aprile / Re-opening April 18th","");
   } else {
      modal( "Informazioni", "Riapertura 18 Aprile / Re-opening April 18th","");
   }
}


/**
 * when scroll 
 */
/*

var dimension = 100;
var originaldimension = 100;
var maxdimension = 200;
var lastScrollTop = 0;
 window.addEventListener('scroll', function() {
    
    let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
  
    if (st > lastScrollTop){
      // downscroll code
      console.log('down');
      if(dimension<= maxdimension)
      dimension =Math.floor(dimension+(this.window.scrollY/100))
   } else {
      // upscroll code
      console.log('up');
      if(dimension> originaldimension)
      dimension =Math.floor(dimension-(this.window.scrollY/100))
   }

    
    
    console.log(dimension);

    let prenobg = document.getElementById('preno')
    if(prenobg != null){
        
    prenobg.style.backgroundSize = "200% 200%"
    }
});
var lastScrollTop = 0;
var dimension = 100;
var originaldimension = 100;
var maxdimension = 200;
// element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
document.addEventListener("scroll", function(){ // or window.addEventListener("scroll"....
   var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
   if (st > lastScrollTop){
      // downscroll code
      console.log('down');
      if(dimension<= maxdimension)
      dimension =Math.floor(dimension+(window.scrollY/90))

      if(dimension >maxdimension)
        dimension = maxdimension
   } else {
      // upscroll code
      console.log('up');
      if(dimension> originaldimension)
      dimension =Math.floor(dimension-(window.scrollY/90))

      if(dimension < originaldimension)
        dimension = originaldimension
   }
   console.log(dimension);

   let prenobg = document.getElementById('preno')
    if(prenobg != null){
        
    prenobg.style.backgroundSize =  ""+dimension+"% "+dimension+"% "
    }

   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}, false);

*/